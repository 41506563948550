.awb-topbar-search {
  margin-left: 20px;
  display: inline-block;
  position: relative;
  bottom: 6px;
}

.awb-topbar-search-input {
  border: 0 !important;
}

.awb-topbar-search-btn {
  background-color: #fff !important;
  color: #ac071e !important;
  border: 0 !important;
}